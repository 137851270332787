import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Icon } from 'react-icons-kit';
import { Link } from 'gatsby';

import Text from '../../common/components/Text';
import Image from '../../common/components/Image';
import Button from '../../common/components/Button';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';

import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import SectionWrapper, { ContentWrapper } from './product.style';

const Product = ({ 
  content, 
  contentClassName, 
  imageClassName,
  btnStyle,
}) => {

  var ProductItem;

  if (contentClassName==='content-right') {
    ProductItem = () => (
      <>
        <div className={contentClassName}>
          <Heading content={content.title} style={{ 'fontWeight': 'bolder' }} />
          <Text content={parse(content.description)} />
          <Link to={content.id} >
            <Button 
              title={content.buttonLabel} 
              icon={<Icon icon={androidArrowForward} />} 
              {...btnStyle} 
            />
          </Link>
        </div>
  
        <div className="image sectionBreakPoint">
          <Image src={content.image.childImageSharp.fluid.src} alt={content.imageAlt} />
        </div>
      </>
    );
  } else {
    ProductItem = () => (
      <>
        <div className="image sectionBreakPoint">
          <Image src={content.image.childImageSharp.fluid.src} alt={content.imageAlt} />
        </div>
  
        <div className={contentClassName}>
          <Heading content={content.title} style={{ 'fontWeight': 'bolder' }} />
          <Text content={parse(content.description)} />
          <Link to={content.id} >
            <Button 
              title={content.buttonLabel}
              icon={<Icon icon={androidArrowForward} />} 
              {...btnStyle} 
            />
          </Link>
        </div>
      </>
    );
  }

  return (
   <SectionWrapper>
      <Container>
        <ContentWrapper>
          <ProductItem />
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

Product.propTypes = {
  btnStyle: PropTypes.object,
};

Product.defaultProps = {
  btnStyle: {
    type: 'button',
    borderRadius: '4px',
    fontWeight: 'bolder',
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    colors: 'primary',
    pl: '50px',
    pr: '50px',    
  }
};

export default Product;
