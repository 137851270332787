import React from 'react';
import { Icon } from 'react-icons-kit';
import { thinRight } from 'react-icons-kit/entypo/thinRight';

import Text from '../../common/components/Text';
import Image from '../../common/components/Image';
import Container from '../../common/components/UI/Container';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion';
import '@reach/accordion/styles.css';
import SectionHeading from '../SectionHeading';
import FeatureWrapper, { FeatureContent, Content, Ipad } from './feature.style';

const Feature = ({
  sectionTitle, 
  sectionDescription, 
  image, 
  services,
  imageAlt
}) => {


  return (
    <FeatureWrapper id="features">
      <Ipad>
        <Image src={image.childImageSharp.fluid.src} alt={imageAlt} />
      </Ipad>
      <Container>
        <FeatureContent>
          <SectionHeading
            mb="20px"
            slogan=""
            title={sectionTitle}
            textAlign="left"
          />
          <Content>
            <Text
              className="caption"
              content={sectionDescription}
            />
            <Accordion>
              {services.map((item) => {
                return (
                  <AccordionItem key={item.id}>
                    <AccordionButton>
                      <strong>{item.title}</strong> <Icon icon={thinRight} />
                    </AccordionButton>
                    <AccordionPanel>{item.desc}</AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Content>
        </FeatureContent>
      </Container>
    </FeatureWrapper>
  );
};

export default Feature;
