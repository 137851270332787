import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import SEO from "../components/seo"
import Feature from '../containers/Feature';
import FaqSection from '../containers/FaqSection';
import ProductContent from '../containers/Product';
import Testimonial from '../containers/Testimonial';
import WorkSection from '../containers/WorkSection';
import CallToAction from '../containers/CallToAction';
import PartnerSection from '../containers/PartnerSection';

const Products = () => {
  
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        PRODUCTS {
          sectionTitle
          sectionDescription
          image {
            childImageSharp {
              fluid(maxWidth: 1018, maxHeight: 839) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          services {
            id
            expanded
            title
            desc
          }
          items {
            id
            color
            title
            description
            buttonLabel
            buttonPath
            image {
              childImageSharp{
                fluid{
                  src
                }
              }
            }
            imageAlt
          }
          seo {
            title
            description
            url
            imageAlt
            twitterProfile
            image {
              publicURL
            }
            twiterImage {
              publicURL
            }
            keywords
          }
        }
        WORKDATA {
          products {
            id
            title
            description
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);

  const { 
    sectionTitle, 
    sectionDescription, 
    image,
    imageAlt,
    services,
    items,
    seo
  } = Data.dataJson.PRODUCTS

  const { products } = Data.dataJson.WORKDATA

  return (
    <>
      <SEO metaData={seo} lang="" />
      <Feature  
        sectionTitle={sectionTitle}
        sectionDescription={sectionDescription}
        image={image}
        services={services}
        imageAlt={imageAlt}
      />
      <WorkSection items={products} />
      {items.map((product, index) => (
        <ProductContent
          content={product}
          contentClassName={(index % 2) === 0 ? 'content-right' : 'content' }
          imageClassName={(index % 2) === 0 ? 'bubble-image-right' : 'bubble-image' }
        />
      ))}
      <Testimonial />
      <PartnerSection />
      <FaqSection />
      <CallToAction />
    </>
  )
 
}

export default Products
